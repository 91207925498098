/* eslint-disable handle-callback-err */

import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext, UserContext } from 'components/context'
import { CreditCardBox, NextPayment, PreviousInvoices } from './components'
import { useLogEvent } from 'components/hooks'
import { Route } from 'react-router'
import { ChangeCreditCard } from './pages'

const Updated = ({ location, t }) => {
  const { logEvent } = React.useContext(UserContext)
  const [status, setStatus] = React.useState('unknown')
  const { post } = React.useContext(UserContext)
  const { membership: { location: { id: locationId } } } = React.useContext(UserContext)

  const searchParams = new URLSearchParams(window.location.search)
  const params = { location_id: locationId }
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }

  React.useEffect(() => {
    if (Object.keys(params).length > 1) {
      logEvent({ action: 'update_credit_card', status: 'in_progress', details: { message: `returned from Verifone with search params: ${window.location.search}` } }).catch(console.log)
      try {
        post('/v1/membership/verifone_credit_card', params)
          .then(res => {
            logEvent({ action: 'update_credit_card', status: 'in_progress', details: { message: 'returned from update cc', result: res } }).catch(console.log)
            if (res.message && res.message === 'RegisterCreditCard::Main::CreditCardAlreadyExistsForCheckoutError') {
              setStatus('exists')
              logEvent({ action: 'update_credit_card', status: 'failure', details: { message: 'credit card already exists', ...params } }).catch(console.log)
            } else {
              setStatus('success')
              logEvent({ action: 'update_credit_card', status: 'success', details: { message: 'successfully registered credit card', ...params, ...res } }).catch(console.log)
            }
          })
          .catch(err => {
            setStatus('error')
            logEvent({ action: 'update_credit_card', status: 'failure', details: { message: `error when registering credit card: ${err}`, ...params } }).catch(console.log)
          })
      } catch (err) {
        logEvent({ action: 'update_credit_card', status: 'failure', details: { message: `error on post: ${err}`, ...params } }).catch(console.log)
      }
    } else {
      logEvent({ action: 'update_credit_card', status: 'in_progress', details: { message: `no search params found in ${window.location}` } }).catch(console.log)
    }
  }, [logEvent, params, post]) // only apply effect once

  // component is _always_ included, only show on matching URL
  if (!location.pathname.includes('payment-method-updated')) return null

  if (!['success', 'error'].includes(status)) return null

  const backgroundColor = status === 'error' ? 'red' : '#07ac52'

  const style = {
    backgroundColor,
    color: 'white',
    padding: '10px',
    fontWeight: 'bold',
  }

  return (
    <div className={'full-width'} style={style}>
      { t(`pages.payment.payment_method.${status === 'error' ? 'not_' : ''}updated`) }
    </div>
  )
}

Updated.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const Payment = ({ location }) => {
  const { t } = React.useContext(GlobalContext)
  const { membership } = React.useContext(UserContext)
  useLogEvent('view_payments')

  if (!membership) return null

  return (
    <div id={'payment'}>
      <h1>{ t('pages.payment.title') }</h1>
      <Updated location={location} t={t} />
      <CreditCardBox membership={membership} t={t} />
      <NextPayment />
      <PreviousInvoices />
    </div>
  )
}

Payment.propTypes = {
  location: PropTypes.object.isRequired,
}

const PaymentWrapper = () => {
  return (
    <>
      <Route path={'/payment'} exact component={Payment} />
      {/* This is a weird little hack for DIBS callback */}
      <Route path={'/payment/payment-method-updated'} component={Payment} />
      <Route path={'/payment/credit-card'} component={ChangeCreditCard} />
    </>
  )
}

export default PaymentWrapper
